import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';

const NikkeGuidesGamemodesStoryPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Campaign</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_story.png"
            alt="Story"
          />
        </div>
        <div className="page-details">
          <h1>Campaign</h1>
          <h2>Guide for the Campaign mode in NIKKE.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Campaign" />
        <p>
          Similar to other gacha games, the Campaign tracks your progress in
          Goddess of Victory: NIKKE. As you push through the Chapters, you will
          unlock more and more other modes that will provide you with additional
          resources which will help you to push further into the Campaign - a
          neat gameplay loop. Still, this guide will focus on the Campaign
          itself and if you want to learn more about different modes, check our
          other guides.
        </p>
        <p>
          In each Chapter, you will have to navigate through a map with your
          team and find the boss of the stage - along the way, you will
          encounter smaller enemies and various materials scattered around the
          map. The numbers of stages in a Chapter varies, starting from 10-15,
          but as you progress though them, it increases.
        </p>
        <p>This how a sample Chapter looks like:</p>
        <StaticImage
          src="../../../images/nikke/generic/guide_story.jpg"
          alt="Story Map"
        />
        <p>As you approach a group of enemies a new screen will appear:</p>
        <StaticImage
          src="../../../images/nikke/generic/guide_stage.webp"
          alt="Stage info"
        />
        <p>
          The view gives you all the essential information that will help you in
          clearing the stage, including:
        </p>
        <ul>
          <li>The element the enemies inside are weak against,</li>
          <ul>
            <li>
              You can also check the full list of enemies by pressing the
              Battlefield Info button,
            </li>
          </ul>
          <li>
            The layout of enemies which translates to what characters you need
            to bring to deal with them - for example, if there's a lot of Far
            enemies, you need to have a character armed with a Sniper Rifle,
            because other weapons will deal reduced damage to them,
          </li>
          <ul>
            <li>
              We explains the differences between{' '}
              <Link to="/nikke/guides/weapon-types">weapons</Link> and give some{' '}
              <Link to="/nikke/guides/team-building">team building tips</Link>{' '}
              in our other guides.
            </li>
          </ul>
          <li>
            Recommended power and your current team power - but the further you
            dwell into Chapters, the often you will have to face enemies with
            twice or even thrice your power,
          </li>
          <li>What rewards you will get for clearing the stage.</li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Battle Types" />
        <p>
          Each stage in a Chapter will belong to one of three battle types that
          affect the behavior of enemies and what goals you have to achieve to
          pass the stage.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/story_battle.jpg"
          alt="Battle types"
        />
        <p>Let's talk about each of them.</p>
        <h5>Destroy</h5>
        <p>
          The majority of stages you belong to this type and it's the simplest
          one among the three. You will be fighting here against waves of
          enemies till the main target appears.
        </p>
        <p>
          If you kill it before the timers runs to 0, you will pass the stage.
        </p>
        <h5>Defense</h5>
        <StaticImage
          src="../../../images/nikke/generic/battle_defense.jpg"
          alt="Defense"
        />
        <p>
          In the Defense battle type, enemies will keep moving from left to
          right side of the screen and you have to stop them. Each time an enemy
          reaches the goal line, you will lose HP points based on the type of
          enemy:
        </p>
        <ul>
          <li>normal monsters are worth 1 HP,</li>
          <li>elite monsters are worth 3 HP,</li>
          <li>boss monsters are worth 5 HP.</li>
        </ul>
        <p>
          You start with 20 HP, so it isn't much and you have to be quick with
          killing whatever appears on the screen. Thankfully, as long as you
          have at least 1 HP when the timer hits 0, you will win.
        </p>
        <p>
          Compared to Destroy, this battle type appears less often in the
          Campaign.
        </p>
        <h5>Base</h5>
        <StaticImage
          src="../../../images/nikke/generic/battle_base.jpg"
          alt="Base"
        />
        <p>
          The Base battle type is a combination of tug of war and capture the
          base modes from other games.
        </p>
        <p>
          The timer is set only at 1 minute and you start with 50/50 split in
          points between you and the enemy. You obtain points by keeping your
          base clear of enemies, but for each enemy present in the base, they
          gain points instead and you lose them.
        </p>
        <p>
          To win the battle, you have to have more points than the enemy once
          the timer hits zero, but if your points reach 0, you automatically
          lose. If you manage to obtain 100 points, you will automatically win
          the battle.
        </p>
        <p>Seems pretty simple, but there are a few important factors:</p>
        <ul>
          <li>
            Flying enemies don't give points even if they sit atop the base so
            you have to focus on ground enemies first,
          </li>
          <li>
            Auto Battle is kinda annoying here as often it focuses on the flying
            enemies first leaving the ground ones in the base,
          </li>
          <li>
            Shotgun characters are super strong in this mode as not only there
            is a lot of enemies clustered together, but also they often stay in
            the short range.
          </li>
        </ul>
        <p>
          Compared to Destroy, this battle type appears less often in the
          Campaign.
        </p>
        <SectionHeader title="EX Stages" />
        <StaticImage
          src="../../../images/nikke/generic/stage_ex.jpg"
          alt="EX Stages"
        />
        <p>
          While progressing through the Chapters, you will rarely encounter EX
          Stages. They are a special type of stage where your team is leveled up
          (or down) to level 80 and you have to use all your knowledge about the
          game mechanics to win.
        </p>
        <p>
          Still, while your levels and gear are normalized, skills aren't - so
          you can use that to make this stage a bit easier to clear.
        </p>
        <SectionHeader title="Hard Mode" />
        <p>
          After you clear a Chapter, the hard mode version of it will unlock.
          The levels and power of enemies inside will increase, but you will be
          able to obtain additional rewards for dealing with them.
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkeGuidesGamemodesStoryPage;

export const Head: React.FC = () => (
  <Seo
    title="Campaign | NIKKE | Prydwen Institute"
    description="Guide for the Campaign mode in Goddess of Victory: NIKKE."
  />
);
